import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { Services } from "../components/Services";
import { Reviews } from "../components/Reviews";
import "../utils/scss/global.scss";
import "../utils/normalize.css";
import "../utils/css/screen.css";
import { Logo } from "../components";
import { LANGUAGE, useStore } from "../context/store";
import GatsbyImage from "gatsby-image";

const BackgroundContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: calc(-3vw - 34px);
  filter: saturate(0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    top: calc(-6vw - 45px);
    background-position-x: 52%;
  }
`;

const Block = styled.div`
  line-height: 1;
  width: 100%;
  height: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10vw;

  @media (max-width: 850px) {
    height: 55vw;
    margin-bottom: 30vw;
  }

  .title,
  .subtitle {
    position: relative;
    z-index: 1;
    font-weight: 600;
    color: #1e262e;
    font-family: Libre Baskerville;
    letter-spacing: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 44px;
    margin-top: -8px;

    @media (max-width: 850px) {
      font-size: 40px;
    }
  }

  .subtitle {
    font-size: 24px;
    font-weight: 800;

    @media (max-width: 850px) {
      font-size: 16px;
    }
  }
`;

const BlurBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  max-height: 450px;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(5px) saturate(160%) contrast(45%) brightness(140%);
`;
const ServicesContainer = styled.div`
  padding-top: calc(100vh - 3vw - 34px);
`;

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  const { language } = useStore();
  const isSpanish = language === LANGUAGE.es;
  const heroImageFluid = data.heroImage.childImageSharp.fluid;
  const reviewsBackgroundImage =
    data.reviewsBackgroundImage.childImageSharp.fluid;

  const sortedServices = [
    "Petition & Forms for Divorce",
    "Legal Documentation Preparation",
    "Notary",
    "Create and Notarize Will & Testament",
    "Renew DACA",
    "Taxes",
    "Insurance",
    "Commercial Insurance",
    "Citizenship Application",
    "Child Travel Consent Letter",
  ];

  const services = posts
    .map(post => ({
      title: post.node.frontmatter.title,
      titulo: post.node.frontmatter.titulo,
      description: post.node.frontmatter.description,
      descripcion: post.node.frontmatter.descripcion,
      content: post.node.frontmatter.content,
      contenido: post.node.frontmatter.contenido,
      thumbnail: post.node.frontmatter.thumbnail?.childImageSharp?.fixed,
      slug: post.node.fields.slug,
    }))
    .sort(
      (a, b) =>
        sortedServices.findIndex(title => title === a.title) -
        sortedServices.findIndex(title => title === b.title)
    );

  return (
    <Layout title={siteTitle} location={location}>
      <SEO
        lang={isSpanish ? "es" : "en"}
        keywords={[
          `divorce`,
          `insurance`,
          `taxes`,
          `legal documentation preparation`,
          `notary`,
          `daca`,
          `citizenship application`,
          `child travel consent letter`,
        ]}
      />
      {/* <Bio /> */}
      {/* {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )} */}

      <BackgroundContainer className="home-hero-image-container">
        <GatsbyImage fluid={heroImageFluid}></GatsbyImage>
        <Block>
          <BlurBackground />
          <div
            style={{
              zIndex: 10,
              width: "32vw",
              maxWidth: 160,
              fontFamily: "libre-baskerville",
            }}
          >
            <Logo />
          </div>
          <div className="title">MAYA</div>
          <div className="subtitle">SEGUROS Y MAS</div>
        </Block>
      </BackgroundContainer>
      <ServicesContainer>
        <Services services={services} />
      </ServicesContainer>
      <Reviews backgroundImage={reviewsBackgroundImage} />
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            titulo
            description
            descripcion
            content
            contenido
            thumbnail {
              childImageSharp {
                fixed(width: 300, height: 215, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    heroImage: file(name: { eq: "landing-image" }) {
      childImageSharp {
        fluid(maxWidth: 1360, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    reviewsBackgroundImage: file(name: { eq: "reviews-image" }) {
      childImageSharp {
        fluid(maxWidth: 1360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
);
