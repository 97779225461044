import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { LANGUAGE, useStore } from "../../context/store";
import COLORS from "../../data/colors";
import { Title } from "../Title";
import GatsbyImage from "gatsby-image";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 24px 80px;
  flex-wrap: wrap;
  max-width: 1360px;

  @media (max-width: 850px) {
    flex-direction: column;
    padding: 24px 24px;
  }

  @media (max-width: 1407px) {
    max-width: 1034px;
  }

  @media (max-width: 1079px) {
    max-width: 706px;
  }
`;

const Service = styled.div`
  margin: 16px 14px;
  width: 300px;
  height: 500px;
  box-shadow: rgb(0 0 0 / 9%) 0px 3px 12px;
  position: relative;
  background-color: ${COLORS.white};

  @media (max-width: 850px) {
    margin: 0 0 40px 0;
  }

  .title {
    font-size: 26px;
    line-height: 1.1;
    letter-spacing: 0.2px;
    text-align: center;
    font-family: Cormorant Garamond;
    font-weight: 600;
    width: 100%;
    padding: 22px 22px 0;
  }

  .subtitle {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 22px;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 1.4;
  }

  .learn-more {
    margin: 24px;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    right: 0;
    letter-spacing: 0.3px;
    text-align: right;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: #454545;
`;

const ServicesTitle = styled(Title)`
  padding: 80px 0 20px 0;

  @media (max-width: 850px) {
    margin: 0 16px;
    padding: 80px 0 0;
  }
`;

const ServiceImage = styled.div`
  width: 100%;
  height: 43%;
  background-color: antiquewhite;

  img {
    position: relative;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const BlankSpace = styled.div`
  flex: 1;
  opacity: 0 !important;
  height: 0 !important;
`;

export const Services = ({
  services,
}: {
  services: {
    title: string;
    titulo: string;
    description: string;
    descripcion: string;
    thumbnail: any;
    slug: string;
    content: string;
    contenido: string;
  }[];
}) => {
  const { language, translations } = useStore();
  const isSpanish = language === LANGUAGE.es;

  return (
    <div
      id="services"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 24px",
      }}
    >
      <ServicesTitle title={translations.our_services} divider={false} />
      <Container>
        {services.map((service, index) => (
          <Service key={`service-${index}`}>
            <ServiceImage>
              <GatsbyImage fixed={service.thumbnail} />
            </ServiceImage>
            <div className="title">
              {isSpanish ? service.titulo : service.title}
            </div>
            <div className="subtitle">
              {isSpanish ? service.descripcion : service.description}
            </div>
            {/* {service.content?.length && (
              <div className="learn-more">
                <a href={service.slug} style={{ color: COLORS.link }}>
                  {translations.see_more}
                </a>
              </div>
            )} */}
          </Service>
        ))}
        {/* Helps with left aligning last card on last row */}
        <BlankSpace />
      </Container>
    </div>
  );
};
