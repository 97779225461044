import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { LANGUAGE, useStore } from "../../context/store";
import { googleReviewsEn, googleReviewsEs } from "./GoogleReviews";
import GatsbyImage from "gatsby-image";

const Review = styled.div`
  padding-bottom: 16px;
  width: 50vw;
  word-break: break-word;
  text-align: center;
  max-width: 500px;

  @media (max-width: 425px) {
    width: 80vw;
  }

  span {
    letter-spacing: 1px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 35%,
      #ffd0ae 35%
    );
  }
`;
const Customer = styled.div`
  width: 50vw;
  text-align: right;
  max-width: 500px;
`;
const Fecha = styled.div`
  width: 50vw;
  text-align: right;
  font-size: 12px;
  max-width: 500px;
`;

const Title = styled.div`
  padding: 80px 0 20px;
  text-align: center;
  font-size: 34px;
  line-height: 1;
  font-family: Cormorant Garamond;
  font-weight: 500;

  @media (max-width: 850px) {
    padding: 0 0 20px;
    margin: 0 16px;
  }
`;

const Background = styled.div`
  position: relative;
  background: antiquewhite;
  margin-bottom: 80px;
`;

export const Reviews = ({ backgroundImage }: any) => {
  const { translations, language } = useStore();

  const customerReviews =
    language === LANGUAGE.es ? [...googleReviewsEs] : [...googleReviewsEn];

  SwiperCore.use([Navigation, Autoplay]);
  return (
    <div>
      <Title>{translations.what_our_clients_say}</Title>
      <Background>
        <GatsbyImage
          fluid={backgroundImage}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          loop
          autoplay={{
            delay: 10000,
            disableOnInteraction: true,
          }}
        >
          {customerReviews.map((r, index) => (
            <SwiperSlide
              key={`swiper-slide-key-${index}`}
              style={{
                height: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Review>
                {r.review.split(" ").map((w, i) => (
                  <span key={`${w}-${i}`}>{`${w} `}</span>
                ))}
              </Review>
              <Customer>&ndash; {`${r.name}`}</Customer>
              <Fecha>{r.date}</Fecha>
            </SwiperSlide>
          ))}
        </Swiper>
      </Background>
    </div>
  );
};
