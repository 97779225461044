type Review = {
  name: string;
  review: string;
  date: string;
  service?: string;
};

export const googleReviewsEs: Review[] = [
  {
    name: "Rosie Robledo",
    review:
      "Quiero dar las gracias a Maya Seguros, Rocio y Juanita por ayudarme con mi Divorcio. Estas damas hicieron de mi divorcio un proceso fácil. Fueron muy útiles en todos los sentidos. (Traducido por Google)",
    date: "Abril 2023",
  },
  {
    name: "Fatima Hernandez",
    review:
      "¡Altamente recomendado! El personal allí es muy servicial, ¡el proceso fue fácil y rápido! (Traducido por Google)",
    date: "Abril 2023",
  },
  {
    name: "A.Saldana Saldana",
    review:
      "¡Lo MEJOR absoluto! Estas chicas realmente saben lo que hacen y te ayudan al máximo. No importa cuál sea tu situación. ¡Definitivamente puede ayudar! Gracias chicas por TODO su arduo trabajo. (Traducido por Google)",
    date: "Abril 2023",
  },
  {
    name: "Meditaya Pat Quintero",
    review:
      "Un lugar que recomiendo. Son muy amables, responsables y siempre tienen una solución y respuesta en lo que hasta hoy yo eh consultado.Si vives en DFW definitivamente necesitas venir y visitar Mayas Seguros y Más.",
    date: "Abril 2023",
  },
  {
    name: "Lizy Escobio",
    review:
      "Rocío ha sido excelente aconsejándome sobre mi situación legal (Traducido por Google)",
    date: "Abril 2023",
  },
  {
    name: "Maria Paredes",
    review:
      "Roció ha sido excelente ayudándome y aconsejándome en mi asunto legal (Traducido por Google)",
    date: "Marzo 2023",
  },
  {
    name: "Melchor Preza",
    review:
      "Excelente servicio al cliente, sobretodo mucha confianza y honestidad. Roció, una persona muy profesional, muchas gracias por todos tus servicios. 100% recomendable.",
    date: "Marzo 2023",
  },
  {
    name: "Yaneth Torres",
    review:
      "Rocío y Juanita son personas muy profesionales que tienen mucho conocimiento legal. Me ayudaron con mi proceso de divorcio y todo el papeleo se llenó muy bien. Recomendaré su oficina y servicios. Rocío es la MEJOR y DIOS LA BENDIGA! (Traducido por Google)",
    date: "Marzo 2023",
  },
  {
    name: "Lucy Salas",
    review: "Muchas Gracias Rocio y Juanita muy amables y profesiones.",
    date: "Marzo 2023",
  },
  {
    name: "Rocio Mendoza",
    review:
      "Rocio Centeno ☺️ y Juanita muchisimass graciasss x su ayuda con respecto a mis taxas y todo tramite q me hicieron sobre mi ssn estoyyy feliz 😃😃   otra vez mass muchass graciasss 😘 las recomiendo al 100% 😃",
    date: "Febrero 2023",
  },
  {
    name: "Yobana Dominguez",
    review:
      "Fue un gran servicio te brindarán toda la ayuda posible eh incluso te aconsejan que hacer.",
    date: "Enero 2023",
  },
  {
    name: "Leon Torres",
    review:
      "La señorita Roció es muy amable y contesta todas tus preguntas. Recomiendo que visiten esta oficina para cualquier trámite!",
    date: "Octubre 2022",
  },
  {
    name: "Yisel Marroquin",
    review:
      "Excelente servicio.. Me ayudaron con mi divorcio, Todo Salio excelente y muy rapido ... Super agradecida con Rocio y Juanita .100% recomendado",
    date: "Octubre 2022",
  },
  {
    name: "Daisy Gonzalez",
    review:
      "Rocio, muy profesional, siempre al tanto de todo lo que necesitaba, muy educada, responda todas mis preguntas o inquietudes. ¡Recomiendo encarecidamente este lugar! (Traducido por Google)",
    date: "Noviembre 2022",
  },
  {
    name: "Francisco Lopez",
    review:
      "Muy buen servicio, me ayudaron a sacar mi seguro de auto super rápido y económico!",
    date: "Noviembre 2022",
  },
  {
    name: "Mariela Rodriguez",
    review:
      "Recomiendo encarecidamente maya seguros y mas excelente servicio al cliente. Juana Servín es la mejor, ha sido de gran ayuda con los problemas que mi esposo y yo tuvimos con nuestra declaración de impuestos. (Traducido por Google)",
    date: "Agosto 2022",
  },
  {
    name: "Albert Wences",
    review:
      "Me ayudaron a solicitar mi acta de nacimiento certificada fuera del estado y acelerar mi solicitud de pasaporte justo a tiempo para mi viaje. ¡No puedo agradecer lo suficiente a las damas de maya seguros y más! (Traducido por Google)",
    date: "Julio 2022",
  },
  {
    name: "Miguel Lopez",
    review:
      "Altamente satisfecho con mi experiencia con mi proceso de divorcio. Rocío Centeno fue una profesional minuciosa. Ella me guía a través del proceso de qué esperar y cuál será el resultado probable. Recomendaría Maya y seguros a cualquier persona que necesite ayuda con un caso de divorcio. (Traducido por Google)",
    date: "Abril 2022",
  },
  {
    name: "Angel Oliver",
    review: `Gran servicio profesional. Realmente satisfecho con el servicio y el tiempo involucrado.
      Precios realmente asequibles.
      Definitivamente los recomendaré.
      (Traducido por Google)`,
    date: "Abril 2022",
  },
  {
    name: "Ivanshinasmr",
    review: `Muy buen servicio, Roció muy profesional y eficiente, me iso sentir muy seguro y cómodo en el
    Proceso de mi divorcio. GRACIAS ROCÍO… y súper recomiendo maya seguros y mas.`,
    date: "Abril 2022",
  },
  {
    name: "Pablo Linares",
    review: `Buen trabajo, seguro y confiable muchas gracias rocío por tu apoyo en mi divorcio fue rápido y económico.`,
    date: "Abril 2022",
  },
  {
    name: "Marcos Reyes",
    review: `Buena atención y muy cómodo el lugar lo recomiendo.`,
    date: "Mayo 2022",
  },
];

export const googleReviewsEn: Review[] = [
  {
    name: "Rosie Robledo",
    review:
      "Quiero dar las gracias a Maya Seguros, Rocio y Juanita por ayudarme con mi Divorcio. Estas damas hicieron de mi divorcio un proceso fácil. Fueron muy útiles en todos los sentidos. (Traducido por Google)",
    date: "April 2023",
  },
  {
    name: "Fatima Hernandez",
    review:
      "Highly recommend! The staff there are super helpful, process was easy and fast!",
    date: "April 2023",
  },
  {
    name: "A.Saldana Saldana",
    review:
      "The absolute BEST! These gals really know their stuff and help you to the fullest. No matter what your situation may be. The can definitely help! Thank you ladies for ALL your hard work.",
    date: "April 2023",
  },
  {
    name: "Meditaya Pat Quintero",
    review:
      "A place that I recommend. They are very kind, responsible and always have a solution and answer in what until today I have consulted.If you live in DFW you definitely need to come and visit Mayas Seguros y Más. (Translated by Google)",
    date: "April 2023",
  },
  {
    name: "Lizy Escobio",
    review: "Rocio has been excellent advising me about my legal situation",
    date: "April 2023",
  },
  {
    name: "Maria Paredes",
    review:
      "Roció has been excellent helping me and advising me about my legal matter",
    date: "March 2023",
  },
  {
    name: "Melchor Preza",
    review:
      "Excellent customer service, above all a lot of trust and honesty. Rocío, a very professional person, thank you very much for all your services. 100% recommendable. (Translated by Google)",
    date: "March 2023",
  },
  {
    name: "Yaneth Torres",
    review:
      "Rocio and Juanita are very professional persons that have a lot of legal knowledge. They assisted me with my divorce process and all the paperwork was filled very good. I will recommend their office and services. Rocio is the BEST and GOD BLESS!",
    date: "March 2023",
  },
  {
    name: "Lucy Salas",
    review:
      "Thank you very much Rocio and Juanita, very kind and professional. (Translated by Google)",
    date: "March 2023",
  },
  {
    name: "Rocio Mendoza",
    review:
      "Rocio Centeno ☺️ and Juanita thank you very much for your help regarding my taxes and all the paperwork you did to me about my ssn I am very happy 😃😃   once again thank you very much 😘 I recommend them 100% 😃 (Translated by Google)",
    date: "February 2023",
  },
  {
    name: "Yobana Dominguez",
    review:
      "It was a great service, they will give you all the help they can and even advise you what to do. (Translated by Google)",
    date: "January 2023",
  },
  {
    name: "Leon Torres",
    review:
      "Miss Rocío is very kind and answers all your questions. I recommend that you visit this office for any procedure! (Translated by Google)",
    date: "October 2022",
  },
  {
    name: "Yisel Marroquin",
    review:
      "Excellent service... They helped me with my divorce, Everything went excellent and very fast... Super grateful to Rocio and Juanita. 100% recommended. (Translated by Google)",
    date: "October 2022",
  },
  {
    name: "Daisy Gonzalez",
    review:
      "Rocio ,Very professional always on top of anything I needed very polite answer all my questions or concerns! I highly recommend this place!",
    date: "November 2022",
  },
  {
    name: "Francisco Lopez",
    review:
      "Very good service, they helped me get my car insurance super fast and cheap! (Translated by Google)",
    date: "November 2022",
  },
  {
    name: "Mariela Rodriguez",
    review:
      "Highly recommend maya seguros y mas great customer service.. Juana Servin is the best she has been so helpful with. Issues my husband and I had with out Tax return.",
    date: "August 2022",
  },
  {
    name: "Albert Wences",
    review:
      "These guys helped me request my certified birth certificate from out of state and expedite my passport application just in time for my trip. I can't thank the ladies at maya seguros y mas enough!",
    date: "July 2022",
  },
  {
    name: "Miguel Lopez",
    review:
      "Highly satisfied with my experience with my divorce process. Rocio Centeno was a thorough professional. She guide me through the process of what to expect and what likely the outcome will be. I would recommend Maya y seguros to anyone needing help with a divorce case.",
    date: "April 2022",
  },
  {
    name: "Angel Oliver",
    review: `Great profesional service. Really satisfied with the service and the time involved.
      Prices a really affordable.
      I will definitely recommend them`,
    date: "April 2022",
  },
  {
    name: "Ivanshinasmr",
    review: `Very good service, Rocío very professional and efficient, I feel very safe and comfortable in the
    Process of my divorce. THANKS ROCÍO… and I highly recommend Maya Seguros and more. (Translated by Google)`,
    date: "April 2022",
  },
  {
    name: "Pablo Linares",
    review: `Good job, safe and reliable, thank you very much Rocío for your support in my divorce, it was fast and cheap. (Translated by Google)`,
    date: "April 2022",
  },
  {
    name: "Marcos Reyes",
    review: `Good attention and very comfortable the place I recommend it. (Translated by Google)`,
    date: "May 2022",
  },
];
